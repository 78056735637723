import './scss/0_settings/variables.scss';
import './scss/3_base/base.scss';
import './scss/5_components/alert.scss';
import './scss/5_components/avatar.scss';
import './scss/5_components/button.scss';
import './scss/5_components/carousel.scss';
import './scss/5_components/container.scss';
import './scss/5_components/contest-consent.scss';
import './scss/5_components/control.scss';
import './scss/5_components/cta.scss';
import './scss/5_components/dialog.scss';
import './scss/5_components/emoji.scss';
import './scss/5_components/form.scss';
import './scss/5_components/header.scss';
import './scss/5_components/icon.scss';
import './scss/5_components/list.scss';
import './scss/5_components/dropdown.scss';
import './scss/5_components/modal.scss';
import './scss/5_components/menu.scss';
import './scss/5_components/placeholder.scss';
import './scss/5_components/placeholder-loading.scss';
import './scss/5_components/sidebar.scss';
import './scss/5_components/spinner.scss';
import './scss/5_components/tabs.scss';
import './scss/5_components/terms.scss';
import './scss/5_components/topbar.scss';
import './scss/5_components/info-pill.scss';
import './scss/5_components/player-avatar-group.scss';
import './scss/6_pages/contest.scss';
import './scss/6_pages/login.scss';
import './scss/6_pages/market.scss';
import './scss/6_pages/join.scss';

import SessionTracking from 'Common/sessionTracking';
import appStatus from 'Common/appStatus';

import { createApp }              from "vue";
import VueSafeHTML                from 'vue-safe-html';
import { svgSpritePlugin }        from 'vue-svg-sprite';
import App                        from "./App.vue";
import Router                     from "./router";
import Store                      from "./store";
import { SimpleFetchAPIDefaults } from "Common/http";

import bus from '@/components/bus.js';
import * as platform from "Common/platformDetector";
import { initSettings, Keys } from "./services/settings"
import { analytics, setupAnalytics, Events, fetchUserData } from './services/analytics';

import * as Sentry from "@sentry/vue";
import getBrowserLocale from "./services/getBrowserLocale";
import i18n from "@/services/i18n";

const params = new URLSearchParams(document.location.search);
const query = Object.fromEntries(params.entries());

let settings = initSettings(process.env, query);
const sprite = '/img/' + settings.get(Keys.WHITE_LABEL) + '/sprite.svg';

let cssTheme = Store.state.theme || settings.get(Keys.DEFAULT_THEME) || 'light';
import('./../../../../../apps/legacy/public/css/' + cssTheme + '.scss');

//TODO: Refactor this into something that can scale to many wihtelabels created via admin panel
let firebaseConfig = {
  apiKey: "AIzaSyANGfYjSXA1UqGXyBd5vWRQfaE60_Sod94",
  authDomain: "mister-8bcca.firebaseapp.com",
  databaseURL: "https://mister-8bcca.firebaseio.com",
  projectId: "mister-8bcca",
  storageBucket: "mister-8bcca.appspot.com",
  messagingSenderId: "898943441540",
  appId: "1:898943441540:web:b35458ea88d7fed2a500d1",
  measurementId: "G-7PVNCJC9CZ"
};

setupAnalytics(firebaseConfig);

Store.commit('setNewToMister', settings.get(Keys.ANALYTICS_FORCE_NEW_TO_MISTER) === 'true');
Store.commit('setAfStatus', settings.get(Keys.ANALYTICS_DEFAULT_STATUS));
Store.commit('setMediaSource', settings.get(Keys.ANALYTICS_SOURCE))
Store.commit('setNonOrganicSources', settings.get(Keys.NON_ORGANIC_SOURCES).split(","));

i18n.changeLanguage(getBrowserLocale());

let brandName = settings.get(Keys.BRAND_NAME);

Store.commit('setBrand', brandName);

document.title = brandName;

if (settings.get(Keys.MISTER_URL) === 'location.origin') {
  settings.set(Keys.MISTER_URL, location.origin);
}
fetchUserData(Store, platform.detect());

const threshol = 60 * 5;
const keyTime = 'bemanager_login_time';
const sessionTracking = new SessionTracking(threshol, appStatus, localStorage, keyTime);
sessionTracking.track(() => {
  analytics.send(Events.LOGIN);
});

// eslint-disable-next-line
if (typeof MRInterface !== 'undefined' && typeof MRInterface.closeSplash !== 'undefined') {
  // eslint-disable-next-line
  MRInterface.closeSplash();
}

// Set default endpoint for http requests
SimpleFetchAPIDefaults.baseURL = "/api2";

function loadHeadElement(type, attributes, onLoadCallback) {
  let element = document.createElement(type);
  for (let attr in attributes) {
    element.setAttribute(attr, attributes[attr]);
  }
  if (onLoadCallback) {
    element.onload = function() {
      onLoadCallback();
    }
  }
  document.head.appendChild(element);
}

function loadBodyElement(type, attributes) {
  let element = document.createElement(type);
  for (let attr in attributes) {
    element.setAttribute(attr, attributes[attr]);
  }
  document.body.appendChild(element);
}

function setFavicon(href) {
  loadHeadElement('link', { href, rel: 'apple-touch-icon' });
  loadHeadElement('link', { href, rel: 'icon', type: 'image/png' });
  loadHeadElement('link', { href, rel: 'shortcut icon' });
}

setFavicon(settings.get(Keys.BRAND_FAVICON));

loadHeadElement('meta', {name: 'theme-color', content: settings.get(Keys.STATUS_BAR_COLOR)});
loadHeadElement('meta', {name: 'apple-mobile-web-app-status-bar-style', content: settings.get(Keys.STATUS_BAR_COLOR)});
loadHeadElement('meta', {name: 'apple-mobile-web-app-capable', content: 'yes'});

if (settings.getBoolean(Keys.AUTH_GOOGLE_ENABLED)) {
  loadHeadElement('script', {src: 'https://accounts.google.com/gsi/client', async: "true"});
}

if (settings.getBoolean(Keys.AUTH_APPLE_ENABLED)) {
  loadHeadElement('script', {src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'});
}

bus.$on('app:debug', function() {
  loadHeadElement('script', {src: 'https://accounts.google.com/gsi/client', async: "true"});
  loadHeadElement('script', {src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'});
});

if (settings.get(Keys.WHITE_LABEL) == "marca") {
  loadBodyElement('script', {src: 'https://e00-ue.uecdn.es/cookies/js/policy_v4.js'});
  loadBodyElement('script', {src: 'https://e00-marca.uecdn.es/assets/consent/v1/ueThirdPartyConsent.js'});
  window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e,tgt){var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target_type=notice&target="+tgt;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("178119d0-5586-4a4c-953e-04aaf9ac0994","bH6MpnRd")})();
  var ueDataLayer = {};
  ueDataLayer.be_page_url = document.location.href.split("?")[0];
  ueDataLayer.be_page_url_qs = document.location.href;
  ueDataLayer.be_page_article_title = document.title;
  ueDataLayer.be_page_section = document.location.pathname == "/" ? "home" : document.location.pathname.split("/")[1];
  ueDataLayer.be_page_subsection1 = typeof document.location.pathname.split("/")[2] == "undefined" ? "" : document.location.pathname.split("/")[2];
  ueDataLayer.be_page_subsection2 = typeof document.location.pathname.split("/")[3] == "undefined" ? "" : document.location.pathname.split("/")[3];
  ueDataLayer.be_page_subsection3 = typeof document.location.pathname.split("/")[4] == "undefined" ? "" : document.location.pathname.split("/")[4];
  ueDataLayer.be_page_subsection4 = typeof document.location.pathname.split("/")[5] == "undefined" ? "" : document.location.pathname.split("/")[5];
  ueDataLayer.be_page_subsection5 = typeof document.location.pathname.split("/")[6] == "undefined" ? "" : document.location.pathname.split("/")[6];
  ueDataLayer.be_page_subsection6 = typeof document.location.pathname.split("/")[7] == "undefined" ? "" : document.location.pathname.split("/")[7];
  ueDataLayer.be_page_domain = "marca.com";
  ueDataLayer.be_page_subdomain = "fantasy";
  ueDataLayer.be_page_hierarchy = ueDataLayer.be_page_domain + "|" + ueDataLayer.be_page_subdomain + "|" + ueDataLayer.be_page_section + "|" + ueDataLayer.be_page_subsection1 + "|" + ueDataLayer.be_page_subsection2 + "|" + ueDataLayer.be_page_subsection3 + "|" + ueDataLayer.be_page_subsection4 + "|" + ueDataLayer.be_page_subsection5 + "|" + ueDataLayer.be_page_subsection6;
  ueDataLayer.be_text_seoTags = "";
  ueDataLayer.be_page_site_version = "";
  ueDataLayer.be_page_cms_template = "";
  ueDataLayer.be_page_content_type = "";
  ueDataLayer.be_navigation_type = "origen"
  ueDataLayer.be_content_premium_detail = "abierto";
  ueDataLayer.be_content_premium = "0";
  ueDataLayer.be_content_signwall_detail = "abierto";
  ueDataLayer.be_content_signwall = "0";
  ueDataLayer.be_page_content_type = "otros";
  // eslint-disable-next-line no-unused-vars
  window.utag_data={};
  (function(a,b,c,d){
    a='https://tags.tiqcdn.com/utag/unidadeditorial/marca/prod/utag.js';
    b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
    a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
  })();
  loadHeadElement('script', {src: 'https://www.googletagmanager.com/gtag/js?id=G-ZJ2MPJ9Q4H'});
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line no-inner-declarations, no-undef
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-ZJ2MPJ9Q4H');
} else if (settings.get(Keys.WHITE_LABEL) == "gazzetta") {
  if (['android', 'ios'].includes(platform.detect())) {
    loadHeadElement('script', {src: 'https://components2.gazzettaobjects.it/rcs_cpmt/v1/distro/gazzetta_noWall_ct.js'});
  } else {
    loadHeadElement('script', {src: 'https://components2.gazzettaobjects.it/rcs_cpmt/v1/distro/gazzetta_ct.js'});
  }
} else {
  window.didomiVersion = 2;
  window.godo = {};
  var gg = window.gg = window.gg || {};
  loadHeadElement('script', {src: 'https://rsc.lavanguardia.com/js/lib/accounts.js'}, ()=> {
    if (gg.didomi && typeof gg.didomi === "object") {
      gg.didomi.init({
        siteName: 'Mundo Deportivo',
        brandColor: '#666',
        subscription: {
          required: true,
          rejectIfSubscriber: false,
          rejectUpButtonIfSubscriber: true,
          url: {
            landing: "https://shopping.mundodeportivo.com/epaper-pago-mensual-9-90.html",
            link: "https://www.mundodeportivo.com/perfil/ev/login"
          }
        }
      });
    }
  });
  loadHeadElement('script', {src: 'https://rsc.lavanguardia.com/js/godo-basex-v1000927.js'});
  // eslint-disable-next-line no-unused-vars
  window.utag_data = {};
  loadHeadElement('script', {src: 'https://components2.gazzettaobjects.it/rcs_tealium/v1/distro/gazzetta-spa/prod/utag.sync.js'});
  loadHeadElement('script', {src: 'https://components2.gazzettaobjects.it/rcs_tealium/v1/distro/gazzetta-spa/prod/utag.js'});
}

let _global = {};
_global.onTapStartEvents = 'ontouchstart' in document ? ['touchstart'] : ['mouseover'];
_global.onTapEndEvents = 'ontouchend' in document ? ['touchend', 'touchmove', 'touchcancel'] : ['mouseout'];

function addMultipleEventListener(element, events, handler) {
  events.forEach(e => element.addEventListener(e, handler));
}

function onTapStartHandler(e) {
  for (var element of e.composedPath()) {
    if (element && element.classList && element.classList.contains('btn')) {
      element.classList.add('tap');
      return;
    }
  }
}

function onTapEndHandler(e) {
  for (var element of e.composedPath()) {
    if (element && element.classList && element.classList.contains('btn')) {
      element.classList.remove('tap');
      return;
    }
  }
}

addMultipleEventListener(document, _global.onTapStartEvents, onTapStartHandler);
addMultipleEventListener(document, _global.onTapEndEvents, onTapEndHandler);

const app = createApp(App)
  .use(Router)
  .use(Store)
  .use(svgSpritePlugin, { url: sprite })
  .use(VueSafeHTML, {
    allowedTags: ['a'],
    allowedAttributes: ['class', 'href'],
  })

app.provide('$settings', settings);
app.provide('i18n', i18n);

app.mount("#app");

if (process.env.VUE_APP_FRONT_SENTRY === 'true') {
  const currentUrl = window.location.hostname;
  Sentry.init({
    app,
    dsn: "https://b572ddadd44d40d99ae64cf043d99936@o269312.ingest.sentry.io/5528853",
    environment: currentUrl,
    release: process.env.BUILD_COMMIT_SHA ? process.env.BUILD_COMMIT_SHA : "dev",
    allowUrls: [currentUrl, "gomister.com", "forge.pub"],
    ignoreErrors: [
      /Can't find variable: \w+_\w+_\w+/,
      /\w+_\w+_\w+ is not defined/,
      "AppsFlyer_trackEvent",
      "onRewardedVideoAvailabilityChanged"
    ],
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", currentUrl],
        routingInstrumentation: Sentry.vueRouterInstrumentation(Router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.05,

    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    debug: true
  });
}
