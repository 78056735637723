export const Names = {
  MISTER_URL: 'MISTER_URL',
  TERMS_AND_CONDITIONS_URL: 'TERMS_AND_CONDITIONS_URL',
  PRIVACY_POLICY_URL: 'PRIVACY_POLICY_URL',
  CONTEST_CASH_TERMS: 'CONTEST_CASH_TERMS',

  IS_WHITE_LABEL: 'IS_WHITE_LABEL',
  WHITE_LABEL: 'WHITE_LABEL',

  NON_ORGANIC_SOURCES: 'NON_ORGANIC_SOURCES',
  JOIN_LEAGUE_REDIRECT: 'JOIN_LEAGUE_REDIRECT',

  AUTH_APPLE_CLIENT_ID:               'AUTH_APPLE_CLIENT_ID',
  AUTH_APPLE_ENABLED:                 'AUTH_APPLE_ENABLED',
  AUTH_APPLE_REDIRECT_URI:            'AUTH_APPLE_REDIRECT_URI',
  AUTH_APPLE_ENDPOINT:                'AUTH_APPLE_ENDPOINT',
  AUTH_EMAIL_ENABLED:                 'AUTH_EMAIL_ENABLED',
  AUTH_EMAIL_ENDPOINT:                'AUTH_EMAIL_ENDPOINT',
  AUTH_EMAIL_RECOVER_ENDPOINT:        'AUTH_EMAIL_RECOVER_ENDPOINT',
  AUTH_EMAIL_RESET_ENDPOINT:          'AUTH_EMAIL_RESET_ENDPOINT',
  AUTH_FACEBOOK_ENABLED:              'AUTH_FACEBOOK_ENABLED',
  AUTH_FACEBOOK_ENDPOINT:             'AUTH_FACEBOOK_ENDPOINT',
  AUTH_FACEBOOK_FORCE_WEB:            'AUTH_FACEBOOK_FORCE_WEB',
  AUTH_FACEBOOK_REDIRECT_METHOD_NAME: 'AUTH_FACEBOOK_REDIRECT_METHOD_NAME',
  AUTH_GOOGLE_CLIENT_ID:              'AUTH_GOOGLE_CLIENT_ID',
  AUTH_GOOGLE_ENABLED:                'AUTH_GOOGLE_ENABLED',
  AUTH_GOOGLE_ENDPOINT:               'AUTH_GOOGLE_ENDPOINT',
  AUTH_GOOGLE_FORCE_WEB:              'AUTH_GOOGLE_FORCE_WEB',
  AUTH_GOOGLE_REDIRECT_URI:           'AUTH_GOOGLE_REDIRECT_URI',
  AUTH_REGISTER_SHOW_CONSENT:         'AUTH_REGISTER_SHOW_CONSENT',
  AUTH_SINGLE_METHOD:                 'AUTH_SINGLE_METHOD',
  AUTH_UE_ENABLED:                    'AUTH_UE_ENABLED',
  AUTH_UE_LOGOUT_URL:                 'AUTH_UE_LOGOUT_URL',

  ANALYTICS_FORCE_NEW_TO_MISTER: 'ANALYTICS_FORCE_NEW_TO_MISTER',
  ANALYTICS_DEFAULT_STATUS: 'ANALYTICS_DEFAULT_STATUS',
  ANALYTICS_SOURCE: 'ANALYTICS_SOURCE',
  ANALYTICS_CAMPAIGN: 'ANALYTICS_CAMPAIGN',

  SHOW_ADD_LEAGUE_CONTEST_CASH: 'SHOW_ADD_LEAGUE_CONTEST_CASH',
  SHOW_ADD_LEAGUE_OTHER_OPTIONS: 'SHOW_ADD_LEAGUE_OTHER_OPTIONS',

  BRAND_NAME: 'BRAND_NAME',
  BRAND_LOGO_URL: 'BRAND_LOGO_URL',
  BRAND_FAVICON: 'BRAND_FAVICON',
  BRAND_SUB_LOGO_LIGHT: 'BRAND_SUB_LOGO_LIGHT',
  BRAND_SUB_LOGO_DARK: 'BRAND_SUB_LOGO_DARK',


  DEFAULT_THEME: 'DEFAULT_THEME',
  STATUS_BAR_COLOR: 'STATUS_BAR_COLOR'
};

export default Names;
