import { SimpleFetchAPI } from 'Common/http';

const httpAjax = new SimpleFetchAPI({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

const contestsStore = {
  namespaced: true,

  state: () => ({
    joiningContestIds: []
  }),

  mutations: {
    addJoiningContestId(state, { contestId }) {
      if (state.joiningContestIds.indexOf(contestId) !== -1) {
        return;
      }

      state.joiningContestIds = [
        ...state.joiningContestIds,
        contestId
      ];
    },

    removeJoiningContestId(state, { contestId }) {
      if (state.joiningContestIds.indexOf(contestId) === -1) {
        return;
      }

      state.joiningContestIds = [...state.joiningContestIds.filter((id) => id !== contestId)];
    },
  },

  actions: {
    async join(context, { contestId }) {
      const formData = new FormData();
      formData.append('id_community', contestId);

      return httpAjax.post('/ajax/contest-join', formData);
    }
  },

  getters: {
    isJoiningContest: (state) => (contestId) => {
      return state.joiningContestIds.indexOf(contestId) !== -1;
    }
  }
};

export default contestsStore;
