import { createStore } from 'vuex';
import http from 'Common/http';
import cookie from '../services/cookie';
import contestsStore from '@/store/contests';

function __refreshUserType(state) {
  if (state.forced_new_to_mister) {
    state.new_to_mister = true;
    return;
  }

  let new_to_mister = false;
  if (state.af_status == "Non-organic" && state.non_organic_sources.includes(state.media_source)) {
    new_to_mister = true;
  }
  state.new_to_mister = new_to_mister;
}

const store = createStore({
  state: () => ({
    user: JSON.parse(localStorage.getItem('user')),

    forceCountry: null,
    af_status: null,
    media_source: null,

    onboarding_email: "",
    non_organic_sources: [],
    new_to_mister: false,
    forced_new_to_mister: false,

    auth: cookie.isAuthenticated(),
    debug: false,

    invitation_seen_after_first_launch: false,

    brand: 'Mister',
    theme: cookie.uiTheme(),

    competitions: [],
    competitionPlayers: {}
  }),

  mutations: {
    setUser(state, user) {
      localStorage.setItem('user', JSON.stringify(user));

      state.user = user;
    },
    setForceCountry(state, country) {
      state.forceCountry = country;
    },
    setNewToMister(state, status) {
      state.forced_new_to_mister = status;
      __refreshUserType(state);
    },
    setAfStatus(state, status) {
      state.af_status = status;
      __refreshUserType(state);
    },
    setDebug(state, debug) {
      state.debug = debug;
    },
    setMediaSource(state, source) {
      state.media_source = source;
      __refreshUserType(state);
    },
    setNonOrganicSources(state, sources) {
      state.non_organic_sources = sources;
      __refreshUserType(state);
    },
    setOnboardingEmail(state, email) {
      state.onboarding_email = email;
    },
    refreshAuth(state) {
      state.auth = cookie.isAuthenticated();
    },
    removeUser(state) {
      localStorage.removeItem('user');
      cookie.deleteCookie('authenticated');

      state.user = null;
    },
    setInvitationSeenAfterFirstLaunch(state, seen) {
      state.invitation_seen_after_first_launch = seen;
    },
    setBrand(state, brand) {
      state.brand = brand;
    },

    setCompetitions(state, competitions) {
      state.competitions = competitions;
    },

    setCompetitionPlayers(state, { competitionId, players }) {
      state.competitionPlayers[competitionId] = players;
    }
  },
  actions: {
    async fetchCompetitions({ commit }) {
      try {
        const response = await http.get('/competitions');

        commit('setCompetitions', response.data.items);
      } catch (err) {
        if (err.name !== 'RequestError') {
          console.error("There was an error trying to fetch competitions", {
            error: err
          });
          return;
        }

        console.error("There was an error trying to fetch competitions", {
          error: err,
          response: err.response
        });
      }
    },

    async fetchCompetitionPlayers({ commit }, competitionId) {
      try {
        const response = await http.get(`/competitions/${competitionId}/players?limit=1000`);

        commit('setCompetitionPlayers', {
          competitionId,
          players: response.data.items
        });
      } catch (err) {
        if (err.name !== 'RequestError') {
          console.error("There was an error trying to fetch competition players", {
            error: err
          });
          return;
        }

        console.error("There was an error trying to fetch competition players", {
          error: err,
          response: err.response
        });
      }
    }
  },

  modules: {
    contests: contestsStore
  },
});

export default store;
